import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/mbeale/timeseriesql"
        }}>{`TimeSeriesQL`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/mbeale/timeseriesql-matplotlib"
        }}>{`TimeSeriesQL-Matplotlib`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/mbeale/timeseriesql-appoptics"
        }}>{`TimeSeriesQL-AppOptics`}</a></li>
    </ul>
    <Title text="Other Writings" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.appoptics.com/using-python-comprehensions/"
        }}>{`Python Comprehensions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stormpath.com/blog/secure-your-rest-api-right-way"
        }}>{`Securing REST APIs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.appoptics.com/best-practices-for-choosing-a-third-party-api/"
        }}>{`Choosing A Third-Party API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@michael.beale"
        }}>{`Medium`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      